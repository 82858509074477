<template>
  <span>
    <v-data-table
      :headers="headers"
      item-key="nowNumber"
      :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
      :items="trainingRecords"
      :server-items-length="itemCount"
      :loading="loading"
      class="training-record-list"
      :options.sync="options"
      :no-data-text="filtersApplied ? 'No results found' : $vuetify.noDataText"
    >
      <template v-slot:[`item.employee`]="{ item }">
        <span
          @click="gotoEmployeeDetails(item)"
          class="msaBlue--text"
          :style="{ cursor: 'pointer' }"
        >
          {{ item.employee }}
        </span>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span
          @click="downloadDocument(item)"
          class="msaBlue--text"
          :style="{ cursor: 'pointer' }"
        >
          {{ item.name }}
        </span>
      </template>
      <template v-slot:[`item.completedAt`]="{ item }">
        {{ item.completedAt | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.expireAt`]="{ item }">
        {{ item.expireAt | filterAsLocalDate }}
      </template>
    </v-data-table>
  </span>
</template>

<script>
export default {
  data() {
    return {
      trainingRecords: [],
      headers: [
        {
          text: 'User',
          value: 'employee',
          class: 'lightGrey',
          align: 'start',
          width: '150px',
        },
        {
          text: 'Name',
          value: 'name',
          class: 'lightGrey',
          align: 'start',
        },
        {
          text: 'Folder',
          value: 'group',
          width: '160px',
          class: 'lightGrey',
          align: 'start',
        },
        {
          text: 'Completed Date',
          value: 'completedAt',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
        {
          text: 'Recertification Date',
          value: 'expireAt',
          width: '160px',
          class: 'lightGrey',
          align: 'center',
        },
      ],
      isLoaded: false,
      loading: false,
      filters: {},
      options: {},
      itemCount: 0,
    };
  },
  watch: {
    options: {
      handler() {
        //needed to stop mutiple calls to the server when page loads
        if (this.isLoaded) {
          this.$emit('optionsUpdated', this.options);
        }
      },
      deep: true,
    },
  },
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.TRAINING_RECORDS_REPORT_FILTER)
      );
    },
  },
  methods: {
    setFilters(filters) {
      this.filters = { ...filters };
    },
    setOptions(options) {
      this.options = { ...options };
    },
    goToDetail(trainingRecord) {
      this.$store.commit('updateSelectedTrainingRecord', trainingRecord);
      this.$router.push({
        name: 'TrainingRecordDetailsPage',
        params: {
          id: trainingRecord.id,
        },
      });
    },
    load() {
      this.loading = true;
      const params = {
        filters: this.filters,
        options: this.options,
      };
      this.$axios
        .post('get-training-records-report?format=json', params)
        .then((response) => {
          this.trainingRecords = response.data.data;
          this.itemCount = response.data.count;
          this.loading = false;
          this.isLoaded = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    gotoEmployeeDetails(trainingRecord) {
      this.$router.push({
        name: 'UserDetails',
        params: { id: trainingRecord.userId },
      });
    },
    downloadDocument(doc) {
      const params = {
        id: doc.id,
        isArchived: false,
        loaderText: 'loading...',
      };

      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios
        .post('download-training-record?format=json', params, options)
        .then((response) => {
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.originalFilename);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$root.showMessage(
              'File not found',
              'File does not exist.',
              () => false,
              null,
              'OK',
            );
          } else {
            return error;
          }
        });
    },
  },
};
</script>

<style>
.training-record-list .v-data-table-header th {
  background-color: #f0f0f0 !important;
  border-bottom: none !important;
}
</style>
